<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol lg="12" class="card-header-with-button">
              <h3>Order Detail - {{ selectedDetail.order_id }}</h3>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="6" class="mb-4">
              <h5>Basic Info</h5>
              <hr />
              <span style="font-size: 12pt; font-weight: bold"
                >{{ selectedDetail.firstname }}
                {{ selectedDetail.lastname }}</span
              ><br />
              {{ selectedDetail.user_id }}<br />
              {{ selectedDetail.phone }}<br /><br />
              <b>Jogger ID:</b> {{ selectedDetail.jogger_id }}
            </CCol>
            <CCol sm="6">
              <h5>Restaurant</h5>
              <hr />
              <span style="font-size: 12pt; font-weight: bold">{{
                selectedDetail.restaurant
              }}</span
              ><br />
              <b>Building:</b> {{ selectedDetail.building }}<br />
              <b>Location:</b> {{ selectedDetail.loc }}<br />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <h5>Timeline</h5>
              <hr />
              <CRow>
                <CCol sm="3" style="text-align: right">
                  <b>Ordered At:</b><br />
                  <b>Paid At:</b> <br />
                  <b>Accepted At:</b><br />
                  <b>Completed At:</b><br />
                  <b>Delivered At:</b><br />
                </CCol>
                <CCol sm="9">
                  {{ selectedDetail.order_date }}<br />
                  {{ selectedDetail.paid }}<br />
                  {{ selectedDetail.accpt_date }}<br />
                  {{ selectedDetail.cmplt_date }}<br />
                  {{ selectedDetail.dlvrd_date }}<br />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="6">
              <h5>Financial Info</h5>
              <hr />
              <b>Item Total:</b> ${{
                parseFloat(selectedDetail.subtotal).toFixed(2)
              }}<br />
              <b>Delivery Fee:</b> ${{
                parseFloat(selectedDetail.delivamt).toFixed(2)
              }}<br />
              <b>Tax:</b> ${{
                parseFloat(selectedDetail.taxamt).toFixed(2)
              }}
              ({{ parseFloat(selectedDetail.taxrate).toFixed(2)}}%)<br />
              <b>Discount:</b> ${{
                parseFloat(selectedDetail.discamt).toFixed(2)
              }}
              ({{ parseInt(selectedDetail.discamt) }}%)<br />
              <b>Receipt Amount:</b> ${{
                parseFloat(selectedDetail.receiptamt).toFixed(2)
              }}<br /><br />
              <b>Restaurant Net Amount:</b> ${{
                parseFloat(selectedDetail.resnetamt).toFixed(2)
              }}<br />
              <b>Jog Fee:</b> ${{
                parseFloat(selectedDetail.joggfee).toFixed(2) 
              }} ({{ parseFloat(selectedDetail.joggrate) }}%)
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12" class="mt-3">
              <h5>Order Items</h5>
              <CDataTable
                :items="orderItemsData"
                striped
                :items-per-page="10"
                :pagination="{ doubleArrows: false, align: 'center' }"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from "../../store";
import constants from "../../data/Constants.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import moment from "moment";
export default {
  name: "Detail",
  data() {
    return {
      selectedDetail: {},
      orderItemsData: [],
    };
  },
  mounted: function () {
    if (this.$route.query.selectedData != undefined) {
      this.selectedDetail = this.$route.query.selectedData;

      this.selectedDetail.order_date =
        // moment(this.selectedDetail.order_date).format("L") +
        // " " +
        // moment(this.selectedDetail.order_date).format("LT");
        //moment(this.selectedDetail.order_date, null).tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm A');
        moment.utc(this.selectedDetail.order_date).local().format("L") + " " +
        moment.utc(this.selectedDetail.order_date).local().format("LT"); 
      this.selectedDetail.paid =
        moment.utc(this.selectedDetail.paid).local().format("L") +
        " " +
        moment.utc(this.selectedDetail.paid).local().format("LT");
      this.selectedDetail.accpt_date =
        moment(this.selectedDetail.accpt_date).local().format("L") +
        " " +
        moment.utc(this.selectedDetail.accpt_date).local().format("LT");
      this.selectedDetail.cmplt_date =
        moment.utc(this.selectedDetail.cmplt_date).local().format("L") +
        " " +
        moment.utc(this.selectedDetail.cmplt_date).local().format("LT");
      this.selectedDetail.dlvrd_date =
        moment.utc(this.selectedDetail.dlvrd_date).local().format("L") +
        " " +
        moment.utc(this.selectedDetail.dlvrd_date).local().format("LT");
      this.selectedDetail.menu_items.forEach((element) => {
        var temp = {
          "Item #": element.item_number,
          Name: element.name,
          Quantity: element.quantity,
          "Total Price": element.price,
        };
        this.orderItemsData.push(temp);
      });
    }
  },
};
</script>
